<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.375 13.9997C25.3714 16.7798 24.3514 19.4626 22.507 21.5429C20.6627 23.6231 18.1213 24.9572 15.3617 25.2937C15.3002 25.3007 15.238 25.2945 15.1791 25.2757C15.1202 25.2568 15.066 25.2257 15.02 25.1844C14.974 25.143 14.9373 25.0924 14.9123 25.0359C14.8873 24.9793 14.8746 24.9181 14.875 24.8562V16.6247H17.5C17.6199 16.6249 17.7386 16.6005 17.8487 16.553C17.9589 16.5054 18.058 16.4357 18.1401 16.3482C18.2222 16.2607 18.2853 16.1573 18.3258 16.0444C18.3662 15.9314 18.3829 15.8114 18.375 15.6917C18.3556 15.4667 18.2518 15.2574 18.0844 15.1058C17.917 14.9542 17.6984 14.8716 17.4726 14.8747H14.875V12.2497C14.875 11.7855 15.0593 11.3404 15.3875 11.0122C15.7157 10.684 16.1608 10.4997 16.625 10.4997H18.375C18.4949 10.4999 18.6136 10.4755 18.7237 10.428C18.8339 10.3804 18.933 10.3107 19.0151 10.2232C19.0972 10.1357 19.1603 10.0323 19.2008 9.91937C19.2411 9.80643 19.2579 9.68639 19.25 9.5667C19.2306 9.34131 19.1265 9.13165 18.9586 8.98002C18.7907 8.82839 18.5716 8.74607 18.3454 8.74967H16.625C15.6967 8.74967 14.8065 9.11842 14.1501 9.77479C13.4937 10.4312 13.125 11.3214 13.125 12.2497V14.8747H10.4999C10.38 14.8744 10.2613 14.8988 10.1512 14.9464C10.041 14.9939 9.94186 15.0636 9.8598 15.1511C9.77774 15.2386 9.71455 15.342 9.67415 15.455C9.63375 15.5679 9.617 15.6879 9.62495 15.8076C9.6443 16.033 9.74842 16.2427 9.91629 16.3943C10.0842 16.5459 10.3033 16.6283 10.5295 16.6247H13.125V24.8584C13.1253 24.9202 13.1126 24.9813 13.0877 25.0378C13.0628 25.0942 13.0262 25.1448 12.9803 25.1861C12.9344 25.2274 12.8803 25.2586 12.8216 25.2775C12.7628 25.2964 12.7007 25.3027 12.6393 25.2959C9.80581 24.9508 7.2051 23.5546 5.35197 21.3834C3.49884 19.2123 2.52835 16.4246 2.63261 13.572C2.85136 7.66576 7.63542 2.8642 13.546 2.63451C15.0763 2.57524 16.6028 2.82521 18.0342 3.36947C19.4656 3.91374 20.7726 4.74111 21.8769 5.80212C22.9812 6.86312 23.8601 8.13595 24.4612 9.54447C25.0622 10.953 25.373 12.4683 25.375 13.9997Z"
      fill="url(#paint0_linear_198_227)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_198_227"
        x1="14"
        y1="2.62598"
        x2="14"
        y2="25.2986"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
    </defs>
  </svg>
</template>
