<script setup>
import { ref, onMounted } from "vue";

const showModal = ref(false);
const hideModal = ref(false);

const setHideModal = () => {
  hideModal.value = true;
};

const setShowModal = () => {
  showModal.value = true;
};

const setCookie = () => {
  document.cookie = "cookie=enable";
  showModal.value = false;
  setTimeout(setHideModal, 1000);
};

onMounted(() => {
  setTimeout(setShowModal, 1);
});
</script>

<template>
  <div
    v-if="!hideModal"
    class="cookie"
    :class="{ 'cookie-show': showModal, 'cookie-hide': !showModal }"
  >
    <div class="cookie-text">
      <h2>Have a cookie</h2>
      <p>
        We use cookies to enhance your browsing experience and analyse our
        traffic. By continuing to browse, or by clicking “OK”, you consent to
        the storing of cookies on your device.
      </p>
    </div>
    <div class="btn cookie-btn" @click="setCookie()">OK</div>
  </div>
  
</template>
