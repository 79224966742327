<script setup>
import Footer from "./Footer.vue";
import CookieModal from "./CookieModal.vue";
import { onMounted } from "vue";

const windowHeight = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  //   document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);
};

onMounted(() => {
  window.addEventListener("resize", () => {
    windowHeight();
  });

  window.addEventListener("orientationchange", function () {
    windowHeight();
  });

  setTimeout(windowHeight, 10);
});
</script>

<template>
  <div class="wrapper">
    <main class="main">
      <div class="container">
        <slot />
      </div>
    </main>

    <div class="wrapper-footer">
      <Footer />
      <CookieModal />
    </div>
  </div>
</template>

<style lang="scss">
html,
body {
  height: calc(var(--vh, 1vh) * 100);
}

.wrapper {
  height: calc(var(--vh, 1vh) * 100);
}
</style>
