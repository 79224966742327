<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.25 2.625H8.75C7.12608 2.62674 5.56917 3.27261 4.42089 4.42089C3.27261 5.56917 2.62674 7.12608 2.625 8.75V19.25C2.62674 20.8739 3.27261 22.4308 4.42089 23.5791C5.56917 24.7274 7.12608 25.3733 8.75 25.375H19.25C20.8739 25.3733 22.4308 24.7274 23.5791 23.5791C24.7274 22.4308 25.3733 20.8739 25.375 19.25V8.75C25.3733 7.12608 24.7274 5.56917 23.5791 4.42089C22.4308 3.27261 20.8739 2.62674 19.25 2.625ZM14 19.25C12.9616 19.25 11.9466 18.9421 11.0833 18.3652C10.2199 17.7883 9.54699 16.9684 9.14963 16.0091C8.75227 15.0498 8.64831 13.9942 8.85088 12.9758C9.05345 11.9574 9.55346 11.0219 10.2877 10.2877C11.0219 9.55346 11.9574 9.05345 12.9758 8.85088C13.9942 8.64831 15.0498 8.75227 16.0091 9.14963C16.9684 9.54699 17.7883 10.2199 18.3652 11.0833C18.9421 11.9466 19.25 12.9616 19.25 14C19.2486 15.3919 18.695 16.7265 17.7107 17.7107C16.7265 18.695 15.3919 19.2486 14 19.25ZM20.5625 8.75C20.3029 8.75 20.0492 8.67302 19.8333 8.5288C19.6175 8.38458 19.4492 8.1796 19.3499 7.93977C19.2506 7.69994 19.2246 7.43604 19.2752 7.18144C19.3259 6.92684 19.4509 6.69298 19.6344 6.50942C19.818 6.32587 20.0518 6.20086 20.3064 6.15022C20.561 6.09958 20.8249 6.12557 21.0648 6.22491C21.3046 6.32425 21.5096 6.49247 21.6538 6.70831C21.798 6.92415 21.875 7.17791 21.875 7.4375C21.875 7.7856 21.7367 8.11944 21.4906 8.36558C21.2444 8.61172 20.9106 8.75 20.5625 8.75ZM17.5 14C17.5 14.6922 17.2947 15.3689 16.9101 15.9445C16.5256 16.5201 15.9789 16.9687 15.3394 17.2336C14.6999 17.4985 13.9961 17.5678 13.3172 17.4327C12.6383 17.2977 12.0146 16.9644 11.5251 16.4749C11.0356 15.9854 10.7023 15.3617 10.5673 14.6828C10.4322 14.0039 10.5015 13.3001 10.7664 12.6606C11.0313 12.0211 11.4799 11.4744 12.0555 11.0899C12.6311 10.7053 13.3078 10.5 14 10.5C14.9283 10.5 15.8185 10.8687 16.4749 11.5251C17.1313 12.1815 17.5 13.0717 17.5 14Z"
      fill="url(#paint0_linear_198_225)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_198_225"
        x1="14"
        y1="2.625"
        x2="14"
        y2="25.375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
    </defs>
  </svg>
</template>
